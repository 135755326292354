import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Financial Planning for SCM",
  "description": "How to allocate your budget properly for supply chain management: what are the things to consider, and why is it important. ",
  "author": "Alva Xiang",
  "categories": ["scm"],
  "date": "2022-10-06T16:00:00.000Z",
  "featured": false,
  "tags": [],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The financial planning of supply chain management sometimes referred to as supply chain budget management, covers all aspects of ordering, reconciliation, billing, and payment businesses. The aim of this budget management is to plan these business processes so that the cost of the supply chain can be allocated more efficiently and hence raise the turnover rate.`}</p>
    <p><strong parentName="p">{`Supply chain management`}</strong></p>
    <p>{`Supply chain management itself is a structural model that connects suppliers, manufacturers, distributors, retailers, and end users as a whole through feedforward information flow and feedback material flow and information flow. The purpose of supply chain management is to maximize profits by managing the information flow, material flow, and capital flow among each organization in the supply chain.`}</p>
    <p>{`The `}<strong parentName="p"><em parentName="strong">{`budget`}</em></strong>{` is a quantitative target set for a certain period of time. It plays a huge role in:`}</p>
    <ul>
      <li parentName="ul">{`Facilitating the preparation and implementation of plans.`}</li>
      <li parentName="ul">{`Promoting communication and coordination of members in the organization.`}</li>
    </ul>
    <p>{`The budget is a key factor to ensure that the enterprise runs in the right direction along the supply chain, which has become even more important in the management of the whole supply chain. It enables managers to focus on the future and brings better development opportunities to managers with a forward-looking vision.`}</p>
    <p>{`The budget of the supply chain starts with`}</p>
    <ol>
      <li parentName="ol">{`The sales revenue forecast from the sales department.`}</li>
      <li parentName="ol">{`Information exchange between suppliers and consumers. This will be assisted by the sales department and procurement department as the intermediary.`}</li>
      <li parentName="ol">{`The information will then be delivered along the supply chain, from the purchasing department of one enterprise to the sales department of another enterprise.`}</li>
      <li parentName="ol">{`The sales department is responsible for receiving and managing all the information required by suppliers and customers.`}</li>
      <li parentName="ol">{`The purchasing department's task is to convert the procurement plan in the production plan into demand information, and send it to the suppliers in a form of electronic ordering.`}</li>
    </ol>
    <p><strong parentName="p">{`Financial Planning for SCM`}</strong></p>
    <p>{`The budget management of the supply chain consists of three parts: Procure to Pay (P2P) cycle, working capital management, and the order-to-cash cycle.`}</p>
    <p>{`➢ Procure to Pay (P2P)`}</p>
    <p>{`The procurement cycle is a trade cycle that materials, commodities or services purchased by the company are transferred to this company. In this process, companies select, receive, and pay for the materials needed for the production of goods or services to be delivered to their customers.`}</p>
    <p>{`➢ Working Capital Management`}</p>
    <p>{`Every company or organization must have a certain amount of income to procure goods and services for its operation. It's a delicate balancing act, and if you don't have enough product in stock to sell to a customer, then you have to spend more to keep the manufactured product going. If there are the company produces more than it can sell, there may not be enough funds to spend on production to keep the company running.`}</p>
    <p>{`Working capital management is an accounting strategy used by companies to balance current assets and liabilities, with the aim of ensuring that the company maintains financial efficiency and keeps the necessary cash flow to cover short-term operating costs.`}</p>
    <p>{`➢ Order-to-cash cycle`}</p>
    <p>{`An order-to-cash cycle starts when the supplier provides a quote to its customer and ends when the payment has been received and the invoice has been settled. In finance, the key factor of this cycle is the accounts receivable.`}</p>
    <p>{`NexPCB adopts an ERP system to manage the accounts receivable and accounts payable. The finance department can view the purchase orders in the system to pay for goods at a certain time by accessing the ERP database. Every month, the supply chain creates the financial budget for the current month and the capital planning forecast for the upcoming three months based on the system data combined with the customer's sales forecast. This allows better planning for warehouse management and enhances the turnover rate. The transaction data of existing orders can also be analyzed further with data analysis`}{`]`}{`\\`}{` to seek more cost reduction opportunities.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      